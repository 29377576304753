import { Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  private expirationBuffer: number = 10; // seconds

  public checkIsTokenExpired(jwtToken: string): boolean {
    const jwtPayload: JwtPayload = this.decodeToken(jwtToken);

    if (!jwtPayload || !jwtPayload.exp) return true;

    const currentTime: number = Date.now();
    const isExpired: boolean = jwtPayload.exp * 1000 < currentTime + this.expirationBuffer; // 10-second expiration buffer for backend requests over a socket

    // if (isExpired) console.warn('Token expired!');

    return isExpired;
  }

  // public getTokenSub(jwtToken: string): number | null {
  //   const jwtPayload: JwtPayload = this.decodeToken(jwtToken);
  //
  //   if (!jwtPayload || !jwtPayload.sub) return null;
  //
  //   return Number(jwtPayload.sub);
  // }

  // public getTokenExpirationDate(jwtToken: string): Date | null {
  //   const jwtPayload: JwtPayload = this.decodeToken(jwtToken);
  //
  //   if (!jwtPayload || !jwtPayload.exp) return null;
  //
  //   return new Date(jwtPayload.exp * 1000);
  // }

  private decodeToken(jwtToken: string): JwtPayload {
    if (!jwtToken) {
      // console.error('Token is empty!');
      return null;
    }

    try {
      return jwtDecode(jwtToken);
    } catch (error) {
      console.error('Error when decoding token:', error);
      return null;
    }
  }
}
