import { Component, Input } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';
import { InputSumModalComponent } from './input-sum-modal/input-sum-modal.component';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-sum',
  templateUrl: './input-sum.component.html',
  styleUrls: ['./input-sum.component.scss']
})
export class InputSumComponent {
  public isInputSumShowed = false;

  @Input()
  public min = 1;

  @Input()
  public max = 99999;

  constructor(private modalController: ModalController) {
  }

  async openInputSumForControl(control: AbstractControl): Promise<void> {
    if (this.isInputSumShowed) return;

    this.isInputSumShowed = true;
    const opts: ModalOptions = {
      component: InputSumModalComponent,
      componentProps: {
        min: this.min,
        max: this.max
      },
      cssClass: 'input-sum',
      backdropDismiss: true,
      keyboardClose: false,
    };
    const modal: HTMLIonModalElement = await this.modalController.create(opts);
    await modal.present();

    const eventDetail = await modal.onDidDismiss();
    this.isInputSumShowed = false;

    if (eventDetail.data) {
      control.setValue(eventDetail.data);
    }
  }
}
