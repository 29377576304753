import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, ParsedUser } from './auth.service';
import { TokenStoreService } from './token-store.service';

@Injectable({
  providedIn: 'root'
})

export class DisputeGuard implements CanActivate {

  constructor(
    private tokenStore: TokenStoreService,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token: string = await this.tokenStore.getToken();

    if (!token) {
      return true;
    }

    if (route.routeConfig.path.includes('/tasks/dispute/')) {
      return true;
    }

    const parsedUser: ParsedUser = this.authService.parsedUser;

    if (parsedUser?.replyOnResolveDispute?.hasReplyOnResolveDispute) {
      return this.router.navigateByUrl('/tasks/dispute/' + parsedUser.replyOnResolveDispute.taskId + '/details', {replaceUrl: true}).then((): boolean => false);
    }

    return true;
  }
}
