import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StoreErrorHandlerService {
  public errors: any[] = [];
  public errorSubject: Subject<any> = new Subject();

  public addError(error: any): void {
    if (this.errors.length > 4) {
      const lastErrors: any[] = this.errors.slice(-5);
      const allDuplicates: boolean = lastErrors.every(err => err?.message === error.message && err?.stack === error.stack);

      if (allDuplicates) return;
    }

    this.errors.push(error);
    this.errorSubject.next(error);
  }

  public clearErrors(): void {
    this.errors = [];
  };
}