import { Component, } from '@angular/core';
import { PAYMENT_INTENT_TYPE } from '../reply.constants';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent {
  public readonly PAYMENT_INTENT_TYPE = PAYMENT_INTENT_TYPE;
}

