import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authService.isAuthenticated) {
      return await this.router.navigateByUrl(`/login?backUrl=${encodeURIComponent(state.url)}`).then((): boolean => false);
    }

    if (route.routeConfig.path.includes('registration') || route.routeConfig.path.includes('order-details')) {
      return true;
    }

    if (this.authService.isUserProfileIncomplete) {
      return this.router.navigateByUrl('/registration/name', {replaceUrl: true}).then((): boolean => false);
    }

    return true;
  }
}
