import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ReplyInfoComponent } from './reply-info/reply-info.component';
import { EditReplyComponent } from './edit-reply/edit-reply.component';
import { TaskResultComponent } from './task-result/task-result.component';
import { AddTaskResultComponent } from './task-result/add-task-result/add-task-result.component';
import { ShowTaskResultComponent } from './task-result/show-task-result/show-task-result.component';
import { PaymentComponent } from './payment/payment.component';
import { RateResultComponent } from './rate-result/rate-result.component';
import { ExtraWorkDescriptionComponent } from './extra-work-description/extra-work-description.component';
import { PaymentResultComponent } from './payment-result/payment-result.component';
import { DisputeDescriptionComponent } from './dispute-description/dispute-description.component';
import { EditTaskResultComponent } from './task-result/edit-task-result/edit-task-result.component';

const routes: Routes = [
  {
    path: ':id',
    children: [
      {
        path: '',
        component: ReplyInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit',
        component: EditReplyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'show-result',
        component: ShowTaskResultComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit-task-result',
        component: EditTaskResultComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'task-result',
        component: TaskResultComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'files',
            component: AddTaskResultComponent,
            canActivate: [AuthGuard],
          },
        ]
      },
      {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-result',
        children: [
          {
            path: ':status',
            component: PaymentResultComponent,
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'rate-result',
        component: RateResultComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'extra-work-description',
        component: ExtraWorkDescriptionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'dispute-description',
        component: DisputeDescriptionComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReplyRoutingModule {
}
