import { EventEmitter, Injectable, NgZone, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token, } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Client, UserDeviceDataResponse, UserDeviceRequest } from '../api-clients/pyjam/client';
import { PlatformService } from './platform.service';
import CallKit, { Order } from '../plugins/android_callkit/callkit.plugin';
import { PhoneInfoService } from '../avatar/services/phone-info.service';
import { filter, first, firstValueFrom } from 'rxjs';
import { NOTIFICATION_TYPE } from '../app.constants';
import { WebSocketController } from './web-socket.controller';
import { SubscriptionsBag } from './subscriptions-bag';
import { AuthService, ParsedToken } from '../auth/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { VoipNotificationsService } from './voip.notifications.service';
import { PermissionStatus as PushPermissionStatus } from '@capacitor/push-notifications/dist/esm/definitions';
import { PermissionStatus as LocalPermissionStatus } from '@capacitor/local-notifications/dist/esm/definitions';
import { PermissionState } from '@capacitor/core/types/definitions';
import { AlertController, AlertOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppStateChangeService } from './app-state-change.service';
import { ToastService } from './toast.service';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  public countNotifications: number = 0;
  public closeAlertNotificationPermissionsEvent: EventEmitter<void> = new EventEmitter<void>();
  private readonly isPushNotificationsAvailable: boolean;
  private _userDeviceId: number | null = null;
  private _pushToken: string | null = null;
  private sb: SubscriptionsBag = new SubscriptionsBag();
  private alertNotificationPermissions: HTMLIonAlertElement;

  constructor(
    private router: Router,
    private afMessaging: AngularFireMessaging,
    private client: Client,
    private platformService: PlatformService,
    private phoneInfoService: PhoneInfoService,
    private ngZone: NgZone,
    private authService: AuthService,
    private voipNotificationsService: VoipNotificationsService,
    private webSocketController: WebSocketController,
    private translate: TranslateService,
    private appStateChangeService: AppStateChangeService,
    private alertController: AlertController,
    private toastService: ToastService,
  ) {
    this.isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

    if (!this.isPushNotificationsAvailable && 'serviceWorker' in navigator) {
      this.addServiceWorkerListeners();
    }

    this.sb.sub = this.authService.parsedToken$.pipe(
      filter((parsedToken: ParsedToken): boolean => !!parsedToken),
      distinctUntilChanged((prev: ParsedToken, curr: ParsedToken): boolean => prev?.userId === curr?.userId),
    ).subscribe(async (): Promise<void> => {
      await this.initializePushNotifications();

      if (this.platformService.isDevice && this.platformService.isIos) {
        await this.voipNotificationsService.initializeVoipPushNotifications();
      }
    });

    this.sb.sub = this.webSocketController.notificationEvent.subscribe((res): void => {
      switch (res.type) {
        case 'notification': {
          this.countNotifications++; // if device, the update additionally takes place from the backend endpoint 'notificationIndex' on app.component
          break;
        }
      }
    });

    this.sb.sub = this.closeAlertNotificationPermissionsEvent.subscribe(async (): Promise<boolean> => this.alertNotificationPermissions?.dismiss());
  }

  public ngOnDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public async checkPushPermissionsWithDevice(callbackCancel: Function = null, callbackConfirm: Function = null): Promise<void> {
    const {receive} = await this.getPushPermissions();

    if (receive === 'denied') {
      console.warn('🟠 Push notifications permission is denied');
      await this.showAlertToEnableNotificationPermissions(callbackCancel, callbackConfirm);
      return;
    } else {
      if (typeof callbackConfirm === 'function') await callbackConfirm();
    }
  }

  public async checkNotificationPermissionStatusWithBrowser(callbackCancel: Function = null, callbackConfirm: Function = null): Promise<void> {
    const isGranted: boolean = await this.getNotificationPermissionStatusWithBrowser();

    if (!isGranted) {
      if (typeof callbackCancel === 'function') await callbackCancel();
    } else {
      if (typeof callbackConfirm === 'function') await callbackConfirm();
    }
  }

  public async initializePushNotifications(): Promise<void> {
    await this.phoneInfoService.getDeviceModel();

    if (this.isPushNotificationsAvailable) {
      await this.initPushNotifications();
    } else {
      await this.initFirebaseMessaging();
    }
  }

  public getUserDeviceId(): number | null {
    return this._userDeviceId;
  }

  public async deleteUserDevice(): Promise<void> {
    if (!this.userDeviceId) return console.error('User device ID is not defined!');

    try {
      await firstValueFrom(this.client.userDeviceDelete(this.userDeviceId));
      this.userDeviceId = null;
    } catch (error) {
      console.error('Error on delete user device', error);
    }
  }

  private async getPushPermissions(): Promise<{ receive: PermissionState; display: PermissionState }> {
    const pushNotificationsPermissions: PushPermissionStatus = await PushNotifications.checkPermissions();
    const localNotificationsPermissions: LocalPermissionStatus = await LocalNotifications.checkPermissions();
    return {receive: pushNotificationsPermissions.receive, display: localNotificationsPermissions.display};
  }

  public async showAlertToEnableNotificationPermissions(callbackCancel: Function = null, callbackConfirm: Function = null): Promise<void> {
    if (this.alertNotificationPermissions) return;

    const opts: AlertOptions = {
      header: this.translate.instant('notificationService.alerts.permissions.header'),
      buttons: [
        {
          text: this.translate.instant('notificationService.alerts.permissions.buttons.cancel'),
          role: 'cancel',
          handler: async (): Promise<void> => {
            if (typeof callbackCancel === 'function') await callbackCancel();
          }
        },
        {
          text: this.translate.instant('notificationService.alerts.permissions.buttons.settings'),
          role: 'confirm',
          handler: async (): Promise<void> => {
            window.cordova.plugins.diagnostic.switchToSettings();

            this.sb.sub = this.appStateChangeService.appResume$.pipe(
              first((isActive: boolean): boolean => isActive),
            ).subscribe(async (): Promise<void> => {
              try {
                const {receive, display} = await this.getPushPermissions();

                if (receive === 'granted') {
                  console.log('Push notification permission:', {receive, display});
                  await this.registerPush();
                  /* sendPushToken in listener event 'registration' */

                  if (typeof callbackConfirm === 'function') await callbackConfirm();
                } else {
                  console.warn('Push notification permission:', {receive, display});
                  await this.toastService.warning(this.translate.instant('notificationService.toasts.permissionNotificationsNotGranted'));

                  if (typeof callbackCancel === 'function') await callbackCancel();
                }
              } catch (error) {
                console.error('Error on register Push notification', error);
                await this.toastService.warning(this.translate.instant('notificationService.toasts.errorRegisterPushNotifications'));

                if (typeof callbackCancel === 'function') await callbackCancel();
              }
            });
          },
        }
      ],
      backdropDismiss: false,
    };

    this.alertNotificationPermissions = await this.alertController.create(opts);
    await this.alertNotificationPermissions.present();
    await this.alertNotificationPermissions.onDidDismiss();
    this.alertNotificationPermissions = null;
  }

  private set userDeviceId(userDeviceId: number) {
    this._userDeviceId = userDeviceId;
  }

  private get userDeviceId(): number | null {
    return this._userDeviceId;
  }

  private set pushToken(pushToken: string) {
    this._pushToken = pushToken;
  }

  private get pushToken(): string | null {
    return this._pushToken;
  }

  private addServiceWorkerListeners(): void {
    if (this.isPushNotificationsAvailable || !('serviceWorker' in navigator)) return;

    // Listen to service worker messages sent via postMessage()
    navigator.serviceWorker.addEventListener('message', async (event): Promise<void> => {
      if (!event.data) return console.error('[ServiceWorker] message data is not defined!');

      console.log('\x1b[35m' + `[ServiceWorker] message:` + '\x1b[0m', event.data);

      await this.navigate(event.data);
    });
  }

  private async initPushNotifications(): Promise<void> {
    if (this.platformService.isDevice && this.platformService.isAndroid) {
      await this.createNotificationChannels();
    }
    await this.addNotificationsListeners();
    await this.requestsNotificationsPermissions();
  }

  public async initFirebaseMessagingIfNeedAndSendToken(): Promise<void> {
    try {
      this.pushToken
        ? await this.sendPushToken(this.pushToken)
        : await this.initFirebaseMessaging();
    } catch (error) {
      console.error('Error on register push if need and send push token', error);
      throw error;
    }
  }

  private async createNotificationChannels(): Promise<void> {
    if (!this.platformService.isDevice || !this.platformService.isAndroid) return;

    try {
      await PushNotifications.createChannel({
        id: 'sound-vibration-channel',
        name: 'Sound and vibration',
        description: 'Channel for notifications with sound and vibration',
        sound: 'default',
        importance: 5,
        vibration: true,
        visibility: 1,
        lights: true,
      });

      await PushNotifications.createChannel({
        id: 'call-sound-vibration-channel',
        name: 'Call sound and vibration',
        description: 'Channel for call notifications with sound and vibration',
        sound: 'avatar2',
        importance: 5,
        vibration: true,
        visibility: 1,
        lights: true,
      });
    } catch (error) {
      console.error('Error on create notification channels', error);
    }
  }

  private async addNotificationsListeners(): Promise<void> {
    try {
      await PushNotifications.removeAllListeners();
      await LocalNotifications.removeAllListeners();

      // On success, we should be able to receive notifications
      await PushNotifications.addListener('registration', async (token: Token): Promise<void> => {
        // alert(`Push registration, token: ${JSON.stringify(token.value)}`);
        // console.log(`Push registration, token: ${token.value}`);

        this.pushToken = token.value;
        await this.sendPushToken(this.pushToken);
      });

      // Some issue with our setup and push will not work
      await PushNotifications.addListener('registrationError', (error: any): void => {
        // alert(`Error push registration: ${JSON.stringify(error)}`);
        console.error(`Error push registration, error: ${error}`);
      });

      // Show us the notification payload if the app is open on our device
      await PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema): Promise<void> => {
        // alert(`Push notification received: ${JSON.stringify(notification)}`);
        console.log(`Push notification received, notification: ${notification}`);

        await this.scheduleNotification(notification);
      });

      // Method called when tapping on a notification
      await PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed): Promise<void> => {
        // alert(`Push action performed: ${JSON.stringify(notification)}`);
        console.log(`Push action performed, notification: ${notification}`);

        await this.navigate(notification.notification.data);
      });

      // Show us the local notification payload if the app is open on our device
      await LocalNotifications.addListener('localNotificationReceived', (notification): void => {
        // alert(`Local notification received: ${JSON.stringify(notification)}`);
        console.log(`Local notification received: ${notification}`);
      });

      // Method called when tapping on a local notification
      await LocalNotifications.addListener('localNotificationActionPerformed', async (notification): Promise<void> => {
        // alert(`Local notification received: ${JSON.stringify(notification)}`);
        console.log(`Local notification received: ${notification}`);

        await this.navigate(notification.notification.extra);
      });

      await CallKit.addListener('answer', async (order: Order): Promise<void> => {
        if (!order) return console.error('CallKit answer. No order!');

        // alert(`CallKit answer, order ID: ${JSON.stringify(order)}`);
        console.log(`CallKit answer, order ID: ${order}`);

        await this.goToOrderDetails(order.id);
      });
    } catch (error) {
      console.error('Error on add notifications listeners', error);
    }
  }

  private async scheduleNotification(notification: PushNotificationSchema): Promise<void> {
    try {
      // Create a local notification
      await LocalNotifications.schedule({
        notifications: [{
          title: notification.title,
          body: notification.body,
          extra: notification.data,
          id: Math.round(Math.random() * 10000), // Use a unique ID for each notification
          smallIcon: 'ic_stat_onesignal_default',
          iconColor: this.isDarkTheme() ? '#ffffff' : '#09203e',
          // Add any other desired options for the notification
        }]
      });
    } catch (error) {
      console.error('Error on schedule notification', error);
    }
  }

  private isDarkTheme(): boolean {
    return document.body.classList.contains('dark');
  }

  private async requestsNotificationsPermissions(): Promise<void> {
    // Request permission to use push notifications
    try {
      const result: PushPermissionStatus = await PushNotifications.requestPermissions();

      if (result.receive === 'granted') {
        await this.alertNotificationPermissions?.dismiss();
        console.log('Push notification permission:', result.receive);
        await this.registerPush();
      } else {
        console.warn('Push notification permission:', result.receive);
      }
    } catch (error) {
      console.error('Error on request Push notification permissions', error);
    }

    // Request permission to show local notifications
    try {
      const result: LocalPermissionStatus = await LocalNotifications.requestPermissions();

      if (result.display === 'granted') {
        console.log('Local notification permission:', result.display);
      } else {
        console.warn('Local notification permission:', result.display);
      }
    } catch (error) {
      console.error('Error on request Local notification permissions', error);
    }
  }

  private async initFirebaseMessaging(): Promise<void> {
    try {
      this.pushToken = await firstValueFrom(this.afMessaging.requestToken);

      this.pushToken
        ? await this.registerUserDevice(this.pushToken)
        : await this.deleteUserDevice();
    } catch (error) {
      console.error(error);
    }
  }

  private async registerUserDevice(pushToken: string): Promise<void> {
    if (!pushToken) return console.error('Token is not defined!');

    // alert(`AFS, user device registration, token: ${JSON.stringify(token)}`);
    // console.log(`AFS, user device registration, token: ${token}`);

    await this.sendPushToken(pushToken);

    try {
      await this.afMessaging.onMessage((message): void => {
        let notificationOptions: NotificationOptions = {
          body: message.data.body,
          icon: '/assets/icon/logo.png',
          data: message.data,
        };
        let notification: Notification = new Notification(message.data.title, notificationOptions);

        notification.addEventListener('click', async (event: Event): Promise<void> => {
          await this.navigate((event.target as Notification).data);
        });
      });
    } catch (error) {
      console.error('Error on register user device', error);
    }
  }

  private async sendPushToken(pushToken: string): Promise<void> {
    if (!pushToken) return console.error('Token is not defined!');

    try {
      const request: UserDeviceRequest = {
        token: pushToken,
        name: this.phoneInfoService.phoneModelWithOS
      } as UserDeviceRequest;

      console.info('\x1b[33m' + 'Send PUSH token:' + '\x1b[0m', pushToken);
      const response: UserDeviceDataResponse = await firstValueFrom(this.client.userDevicePost(request));
      console.info('\x1b[33m' + 'Received device ID:' + '\x1b[0m', response?.data?.id);

      this.userDeviceId = response.data.id;
    } catch (error) {
      console.error('Error on registration user device', error);
    }
  }

  private async navigate(data: any): Promise<void> {
    if (!data) return console.error('Data is not defined!');

    switch (Number(data.type)) {
      case NOTIFICATION_TYPE.MESSAGE:
        if (!this.isPushNotificationsAvailable) return;
        if (!data?.chatId) return console.error('chatId is not defined!');
        try {
          const deliveredNotifications = await PushNotifications.getDeliveredNotifications();
          deliveredNotifications.notifications = deliveredNotifications.notifications
            .map((deliveredNotification) => data.title == deliveredNotification.title ? deliveredNotification : null)
            .filter(Boolean);

          await PushNotifications.removeDeliveredNotifications(deliveredNotifications);
        } catch (error) {
          console.error('Error on remove delivered notifications', error);
        } finally {
          await this.ngZone.run(async (): Promise<void> => {
            await this.router.navigate(['chats', data.chatId]);
          });
        }
        break;
      case NOTIFICATION_TYPE.OFFER:
      case NOTIFICATION_TYPE.EXTRA_WORK:
      case NOTIFICATION_TYPE.CHAT:
      case NOTIFICATION_TYPE.EXTRA_WORK_REJECTED:
      case NOTIFICATION_TYPE.EXTRA_WORK_OFFER_EXTRA_PAY:
      case NOTIFICATION_TYPE.EXTRA_WORK_OWNER_APPROVE:
      case NOTIFICATION_TYPE.EXTRA_WORK_PAID:
      case NOTIFICATION_TYPE.EXTRA_WORK_WORKER_APPROVE:
      case NOTIFICATION_TYPE.EXTRA_WORK_PENDING:
      case NOTIFICATION_TYPE.EXTRA_WORK_WORKER_ADDED_RESULTS:
      case NOTIFICATION_TYPE.EXTRA_WORK_WAIT_PAY:
      case NOTIFICATION_TYPE.DISPUTE_CANCELED:
      case NOTIFICATION_TYPE.EXTRA_WORK_DELETED:
      case NOTIFICATION_TYPE.EXTRA_WORK_OWNER_APPROVE_RESULTS:
      case NOTIFICATION_TYPE.PAYMENT_CANCELED:
      case NOTIFICATION_TYPE.PAYMENT_AFTER_5_DAYS:
        if (!data?.chatId) return console.error('chatId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['chats', data.chatId]);
        });
        break;
      case NOTIFICATION_TYPE.COMMENT:
        if (!data?.taskId) return console.error('taskId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['tasks', data.taskId, 'comments']);
        });
        break;
      case NOTIFICATION_TYPE.REPLY:
      case NOTIFICATION_TYPE.STATUS:
      case NOTIFICATION_TYPE.INACTIVITY_EXTRA_WORK:
      case NOTIFICATION_TYPE.INACTIVITY_DEADLINE:
      case NOTIFICATION_TYPE.INACTIVITY_OWNER:
      case NOTIFICATION_TYPE.DISPUTED:
      case NOTIFICATION_TYPE.DEADLINE:
      case NOTIFICATION_TYPE.REPLY_DATA_UPDATED:
        if (!data?.replyId) return console.error('replyId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['reply', data.replyId]);
        });
        break;
      case NOTIFICATION_TYPE.TASK:
      case NOTIFICATION_TYPE.OFFER_STATUS_ACCEPTED:
      case NOTIFICATION_TYPE.OFFER_STATUS_REJECTED:
      case NOTIFICATION_TYPE.DISPUTED_TASK_FOR_TRUSTED_USER:
        if (!data?.taskId) return console.error('taskId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['tasks', data.taskId]);
        });
        break;
      case NOTIFICATION_TYPE.PAYMENT_SUCCEEDED:
      case NOTIFICATION_TYPE.PAYMENT_FAILED:
        if (!data?.paymentId) return console.error('paymentId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['profile/balance']);
        });
        break;
      case NOTIFICATION_TYPE.ONBOARDING:
        // if (!data?.onboardingId) return console.error('onboardingId is not defined!');
        //
        // await this.ngZone.run(async (): Promise<void> => {
        //   await this.router.navigate(['profile/balance']);
        // });
        break;
      case NOTIFICATION_TYPE.OFFER_CREATE_TASK:
        if (!data?.categoryId) return console.error('categoryId is not defined!');

        await this.ngZone.run(async (): Promise<void> => {
          await this.router.navigate(['tasks/add/category']);
        });
        break;
      case NOTIFICATION_TYPE.AVATAR_ORDER:
        if (!data?.avatarOrderId) return console.error('avatarOrderId is not defined!');

        await this.goToOrderDetails(data.avatarOrderId);
        break;
    }
  }

  private async goToOrderDetails(orderId: number): Promise<void> {
    console.log('Notification service, call push, orderId', orderId);

    await this.ngZone.run(async (): Promise<void> => {
      await this.router.navigate(['avatar/order-details/', orderId]);
    });
  }

  public async getNotificationPermissionStatusWithBrowser(): Promise<boolean> {
    if (!('Notification' in window)) {
      const errorMessage: string = this.translate.instant('notificationService.toasts.errorNotificationsNotSupported');
      console.error(errorMessage);
      await this.toastService.error(errorMessage);
      return false;
    }

    if (!('permissions' in navigator)) {
      console.warn('API Permissions is not supported');
      return true;
    }

    let result: boolean = false;
    try {
      const notificationsPermissionStatus: PermissionStatus = await navigator.permissions.query({name: 'notifications' as PermissionName});
      switch (notificationsPermissionStatus.state) {
        case 'granted':
          console.log('Access to notifications granted.');
          result = true;
          break;
        case 'prompt':
          console.info('The permission request for geolocation is in a prompt state.');
          result = await this.requestNotificationPermissionWithBrowser();
          break;
        case 'denied':
          console.warn('Access to notifications denied.');
          await this.toastService.warning(this.translate.instant('notificationService.toasts.allowBrowserNotifications'));
          break;
      }
      return result;
    } catch (error) {
      const errorMessage: string = this.translate.instant('notificationService.toasts.errorCheckingNotificationsPermissions');
      console.error(errorMessage);
      await this.toastService.error(errorMessage);
      throw error;
    }
  }

  private async requestNotificationPermissionWithBrowser(): Promise<boolean> {
    let result: boolean = false;
    try {
      const notificationPermission: NotificationPermission = await window.Notification.requestPermission();
      switch (notificationPermission) {
        case 'granted':
          console.log('Access to notification granted');
          result = true;
          break;
        case 'default':
          console.info('Access to notification default');
          break;
        case 'denied':
          console.warn('Access to notification denied');
          break;
      }
      return result;
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      throw error;
    }
  }

  private async registerPush(): Promise<void> {
    await PushNotifications.register(); // Register with Apple / Google to receive push via APNS/FCM
  }

  public async registerPushIfNeedAndSendPushToken(): Promise<void> {
    try {
      this.pushToken
        ? await this.sendPushToken(this.pushToken)
        : await this.registerPush();
    } catch (error) {
      console.error('Error on register push if need and send push token', error);
      throw error;
    }
  }
}
