import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/*

Если использовать ion-router-outlet, то ActivatedRoute возвращается правильный, всё норм,

Но, почему-то, когда происходит навигация на урл с квери параметрами,
в нём создаётся всё ещё правильный какой-то объект, но ангулару надо чтобы там был именно определённый объект, а не правильный, но другой инстанс.

Поэтому, этот фикс резолвит из роутера родной ангуларовский ActivatedRoute, чтобы использовать его в относительной навигации
вместо того, который подсунул ионик.

 */

@Injectable({
  providedIn: 'root'
})
export class ActivatedRouteFixService {

  public constructor(
    private _router: Router,
  ) {
  }

  public getActivatedRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
    let stack: ActivatedRoute[] = [];
    stack.push(this._router.routerState.root);

    let target: ActivatedRoute = null;

    while (stack.length) {
      let ar = stack.pop();
      stack.push(...ar.children);

      if (ar.component == activatedRoute.component) {
        target = ar;
        break;
      }
    }

    if (!target) {
      //throw new Error('Can not found good activated route');
      console.error('Cannot find proper activated route!');
    }

    return target;
  }

  public getActivatedRouteByMarker(marker: string): ActivatedRoute {
    let stack: ActivatedRoute[] = [];
    stack.push(this._router.routerState.root);

    let target: ActivatedRoute = null;

    while (stack.length) {
      let ar = stack.pop();
      stack.push(...ar.children);

      if (ar.snapshot?.data?.marker == marker) {
        target = ar;
        break;
      }
    }

    if (!target) {
      //throw new Error('Can not found good activated route');
      console.error('Cannot find proper activated route!');
    }

    return target;
  }
}

