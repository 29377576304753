import { Component } from '@angular/core';
import { ModalComponentBase } from '../../services/modal-window';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'dispute-timeout-popup',
  templateUrl: './dispute-timeout-popup.component.html',
  styleUrls: ['./dispute-timeout-popup.component.scss'],
})
export class DisputeTimeoutPopupComponent extends ModalComponentBase {

  constructor(
    protected modalController: ModalController,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected location: Location,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  onOkClick() {
    super.dismiss().then();
  }
}
