import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BackButtonComponent } from './back-button/back-button.component';
import { AvatarComponent } from './avatar/avatar.component';
import { InitialsPipe } from './avatar/initials.pipe';
import { DateTimeComponent } from './date-time/date-time.component';
import { DateTimeModalComponent } from './date-time/date-time-modal/date-time-modal.component';
import { ImageAddingComponent } from './image-adding/image-adding.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SizeFilePipe } from './pipes/size-file.pipe';
import { IconForFilePipe } from './pipes/icon-for-file.pipe';
import { LinkInTextPipe } from './pipes/link-in-text.pipe';
import { ContextMenuContentComponent } from './context-menu/context-menu-content.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { ContextMenuItemComponent } from './context-menu/context-menu-item';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { StarsComponent } from './stars/stars.component';
import { StarsRatingComponent } from './custom-controls/stars-rating/stars-rating.component';
import { ImagesSetComponent } from './images-set/images-set.component';
import { PriceLocalePipe } from './pipes/priceLocale.pipe';
import { SaveButtonComponent } from './save-button/save-button.component';
import { FilesSetComponent } from './files-set/files-set.component';
import { DirectivesModule } from '../directives/directives.module';
import { PhoneInputComponent } from './custom-controls/phone-input/phone-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddDescriptionToImageComponent } from './add-description-to-image/add-description-to-image.component';
import { PaymentInputComponent } from './payment-input/payment-input.component';
import { InputSumModalComponent } from './input-sum/input-sum-modal/input-sum-modal.component';
import { InputSumComponent } from './input-sum/input-sum.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DisputeConfirmationPopupComponent } from './dispute-confirmation-popup/dispute-confirmation-popup.component';
import { DisputeTimeoutPopupComponent } from './dispute-timeout-popup/dispute-timeout-popup.component';
import { LanguageChooseComponent } from './custom-controls/language-choose/language-choose.component';
import { ReportContentPopupComponent } from './report-content-popup/report-content-popup.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  declarations: [
    BackButtonComponent,
    AvatarComponent,
    InitialsPipe,
    DateTimeComponent,
    DateTimeModalComponent,
    ImageAddingComponent,
    ProgressBarComponent,
    SizeFilePipe,
    IconForFilePipe,
    LinkInTextPipe,
    ContextMenuContentComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    ImageSliderComponent,
    StarsComponent,
    StarsRatingComponent,
    ImagesSetComponent,
    PriceLocalePipe,
    SaveButtonComponent,
    FilesSetComponent,
    PhoneInputComponent,
    LanguageChooseComponent,
    AddDescriptionToImageComponent,
    PaymentInputComponent,
    InputSumModalComponent,
    InputSumComponent,
    DisputeConfirmationPopupComponent,
    DisputeTimeoutPopupComponent,
    ReportContentPopupComponent,
  ],
  exports: [
    BackButtonComponent,
    AvatarComponent,
    InitialsPipe,
    DateTimeComponent,
    ImageAddingComponent,
    ProgressBarComponent,
    SizeFilePipe,
    IconForFilePipe,
    LinkInTextPipe,
    ContextMenuContentComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    ImageSliderComponent,
    StarsComponent,
    StarsRatingComponent,
    ImagesSetComponent,
    PriceLocalePipe,
    SaveButtonComponent,
    FilesSetComponent,
    PhoneInputComponent,
    LanguageChooseComponent,
    AddDescriptionToImageComponent,
    PaymentInputComponent,
    InputSumModalComponent,
    InputSumComponent,
    DisputeConfirmationPopupComponent,
    DisputeTimeoutPopupComponent,
    ReportContentPopupComponent,
  ]
})
export class UiComponentsModule {
}
