import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, take, tap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TelegramService {
  private readonly url: string = `https://eurasia.ee/tgbot/tg2.php?avatar_error`;

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  public async sendMessage(payload: any): Promise<void> {
    if (!payload) return;

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    const payloadStringify: string = JSON.stringify(payload);

    this.httpClient.post(
      this.url,
      payloadStringify,
      {headers}
    ).pipe(
      take(1),
      tap((response) => console.log('Sending to Telegram was successful:', response)),
      catchError(async (error) => console.error('Error when sending an error to the Telegram:', error))
    ).subscribe();
  }
}
