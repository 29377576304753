import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    value = value
      .replace('(', '')
      .replace(')', '');

    let initials: string = '';

    if (value) {
      value = value.trim();
      let array: string[] = value.split(' ');

      for (let i = 0; i < array.length; i++) {
        if (array[i].length > 0) {
          initials += array[i][0];
        }
      }

      initials = initials.toLocaleUpperCase();
    }
    return initials.length > 2 ? initials[0] + initials[1] : initials;
  }
}

