import { Component } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';
import { DateTimeModalComponent } from './date-time-modal/date-time-modal.component';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent {
  public isDatePickerShowed = false;

  constructor(
    private modalController: ModalController
  ) {
  }

  async openDatePickerForControl(control: AbstractControl, showTime: boolean = true, minDate?: string, maxDate?: string): Promise<void> {
    if (this.isDatePickerShowed) return;

    this.isDatePickerShowed = true;
    const opts: ModalOptions = {
      component: DateTimeModalComponent,
      componentProps: {
        value: control.value,
        minDate: minDate,
        maxDate: maxDate,
        showTime: showTime,
      },
      cssClass: 'date-picker',
      backdropDismiss: true,
      keyboardClose: false,
    };
    const modal: HTMLIonModalElement = await this.modalController.create(opts);
    await modal.present();

    const eventDetail = await modal.onDidDismiss();
    this.isDatePickerShowed = false;

    if (eventDetail.data) {
      control.setValue(new Date(eventDetail.data).toISOString());
    }
  }
}
