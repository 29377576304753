import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { defaultStackParser } from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { enableDebugTools } from '@angular/platform-browser';


if (environment.isInitSentry) {
  Sentry.init({
    dsn: 'https://94aa0825c3d54ebcb73bff73e1865b85@o4504055350689793.ingest.sentry.io/4504055353442306',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', 'https://dev.pyjam.com/api'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    stackParser: defaultStackParser,
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule).then(ref => {
  const applicationRef = ref.injector.get(ApplicationRef);
  const appComponent = applicationRef.components[0];
  enableDebugTools(appComponent);
})
  .catch(err => console.log(err));

const paypalClientId = environment.paypal.clientId;

if (paypalClientId != null) {
  addPaypalCardFields(paypalClientId);
}

function addPaypalCardFields(paypalClientId: string) {
  const scriptDiv = document.createElement('script');
  scriptDiv.src = `https://www.paypal.com/sdk/js?intent=authorize&client-id=${paypalClientId}&debug=true&currency=EUR&components=buttons,card-fields`;
  document.getElementsByTagName('head')[0].insertAdjacentElement('afterbegin', scriptDiv);
}
