import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: StarsRatingComponent
  }]
})
export class StarsRatingComponent implements ControlValueAccessor {
  @Input() public width: number;
  @Input() public activeColor: string = '#FFD700';
  @Input() public fontSize: number = 32;

  @Input() set value(stars: number) {
    this._value = stars || null;
    this.onChange(this._value);
  }

  get value() {
    return this._value;
  }

  public stars: number[] = [1, 2, 3, 4, 5];
  private _value: number;


  public writeValue(stars: number): void {
    this.value = stars;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (stars): void => {
  };

  private onTouched = (): void => {
  };
}
