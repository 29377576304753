import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { Client, ReplyOrReplyTaskDataResponse, ReplyResponse } from '../../api-clients/pyjam/client';
import { ToastService } from '../../services/toast.service';
import { AuthService } from '../../auth/auth.service';
import { ReplyStatus } from '../reply.constants';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRouteFixService } from '../../services/activated-route-fix.service';
import { EventService } from '../../services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'reply-info',
  templateUrl: './reply-info.component.html',
  styleUrls: ['./reply-info.component.scss'],
})
export class ReplyInfoComponent {
  public readonly ReplyStatus = ReplyStatus;
  public reply: ReplyResponse;

  constructor(
    private activatedRoute: ActivatedRoute,
    private activatedRouteFixService: ActivatedRouteFixService,
    private loadingService: LoadingService,
    private client: Client,
    private toastService: ToastService,
    private router: Router,
    public authService: AuthService,
    private navigationService: NavigationService,
    private eventBus: EventService,
    private translate: TranslateService,
  ) {
  }

  async ionViewWillEnter(): Promise<void> {
    if (this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params?.id) {
      try {
        await this.loadingService.start();

        await this.loadReplyInfo(this.activatedRoute.snapshot.params?.id);
      } catch (error) {
        console.error(error);
      } finally {
        await this.loadingService.stop();
      }
    }
  }

  public async loadReplyInfo(id: number): Promise<void> {
    try {
      const data: ReplyOrReplyTaskDataResponse = await firstValueFrom(this.client.replyGet(id, false));
      this.reply = data.data;
    } catch (error) {
      this.translate.get('reply.replyInfo.responseWasRemoved').subscribe(async (t): Promise<void> => {
        await this.toastService.warning(t);
      });
      await this.navigationService.goBack();
    }
  }

  public async onReplyApproveClick(): Promise<void> {
    await this.router.navigate(['/reply/' + this.reply.id + '/payment']);
  }

  public async onReplyRejectClick(): Promise<void> {
    try {
      await firstValueFrom(this.client.replyReject(this.reply.id));
      await this.loadReplyInfo(this.reply.id);
    } catch (error) {
      console.error(error);
      // await this.httpErrorHandlerService.handleHttpException(error);
    }
  }

  public async onReplyEditClick(): Promise<void> {
    await this.router.navigate(['edit'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  public async onReplyDeleteClick(): Promise<void> {
    try {
      await firstValueFrom(this.client.replyDelete(this.reply.id));
      this.eventBus.publish('task-detail:updated');
      await this.navigationService.goBack();
    } catch (error) {
      console.error(error);
      // await this.httpErrorHandlerService.handleHttpException(error);
    }
  }

  public async onReplySendResultClick(): Promise<void> {
    await this.router.navigate(['task-result/files'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  public async onShowTaskResultClick(): Promise<void> {
    await this.router.navigate(['show-result'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  public async onGoToChatClick(): Promise<void> {
    await this.router.navigate(['chats', this.reply.chat_id]);
  }

  public async onShowBalanceClick(): Promise<void> {
    await this.router.navigate(['/profile/balance']);
  }

  public getParams(): { percent: number, amount: number } {
    if (this.reply.user_id == this.authService.parsedToken.userId) {
      return {
        percent: this.reply.dispute_resolve_result.contractor_percent,
        amount: this.reply.dispute_resolve_result.contractor_amount
      };
    } else {
      return {
        percent: this.reply.dispute_resolve_result.owner_percent,
        amount: this.reply.dispute_resolve_result.owner_amount
      };
    }
  }
}
